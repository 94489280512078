import React from "react"
import {Link} from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import SignUpEmail from "../../../components/signUpEmail"
import AccordionComponent from "../../../components/AccordionComponent"
import img1 from "../../../images/marketing-services/content-marketing/content-marketing-img-1.svg";
import img2 from "../../../images/marketing-services/content-marketing/content-marketing-img-2.svg";
import img3 from "../../../images/marketing-services/content-marketing/content-marketing-img-3.svg";
import img4 from "../../../images/marketing-services/content-marketing/content-marketing-img-4.svg";

const contentMarketingPage = () => (
  <Layout css="contentMarketingPageCss">
    <SEO title="Content Marketing Agency Boston"
         description={"Our Boston-based Content Marketing Agency makes sure your content clicks with your customer and gets clicks for your business!"}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1><span className={"red"}>CONTENT MARKETING</span> IS A <span className={"red"}>COMMITMENT,</span> NOT
            A <span className={"red"}>CAMPAIGN</span></h1>
          <p className={"subtitle"}>As a business or a brand owner with an online presence, you have probably
            heard the phrase "content is king." Content marketing services like ours exist for this
            reason—to create high-quality and engaging content.</p>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img2}/>
          </div>
          <div className={"flex-equal-width m-3"}>
            <div className={"text-align-center"}>
              <h2>OUR FOCUS</h2>
            </div>
            <p className={"black-text align-items-center"}>Avantcy Marketing understands the importance of premium
              content in:</p>
            <ul className={""}>
              <li><strong>Effective online marketing</strong></li>
              <li><strong>Earning trust with site visitors</strong></li>
              <li><strong>Captivating a viewers heart and attention</strong></li>
              <li><strong>A visitor is potentially buying your product.</strong></li>
            </ul>
            <p className={"black-text align-items-center"}>As content marketing experts, we also know better than
              anyone the amount of effort to develop quality evergreen content. Due to the importance of content
              marketing, having a capable team handling it makes the difference. Therefore, we established our Boston
              content marketing agency to give you a place to run to for all your content marketing needs.</p>
          </div>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>CONTENT CREATION DONE <span>RIGHT</span></h2>
      </div>
      <h3 className={"subtitle m-bottom-75"}>When done right, content creation and marketing has the potential to lead
        to explosive sales. If you are tired of managing subpar content, you have visited the right content marketing
        Boston service. Avantcy Marketing believes in doing things well, and your content isn't exempted.</h3>
      <AccordionComponent label="Strategizing and Keyword Research">
        <p>What type of business do you run? What are your content marketing goals? If you can inform us, our content
          marketing agency in Boston will help you develop strategies to implement them. When strategizing, we run
          keyword research for search engine optimization to convert your target audience. Some factors we monitor
          during keyword research are:</p>
        <ul>
          <li>Competitor keyword analysis</li>
          <li>User intent</li>
          <li>Keyword search volume</li>
          <li>Cost-per-click</li>
        </ul>
      </AccordionComponent>
      <AccordionComponent label="Developing Content">
        <p>In a bid to ensure content creation is done right, Avantcy Marketing as a content marketing Boston service
          provider,
          creates a management schedule. This involves building an appropriate content calendar that features future
          topics.</p>
      </AccordionComponent>
      <AccordionComponent label="Content Creation">
        <p>We develop tailored content with the help of our capable, creative team. Content creation is the core of
          content marketing efforts in Boston, and we create unique content for you. A bonus is that if you have
          revisions, Avantcy Marketing's team will take care of them in no time.</p>
      </AccordionComponent>
      <AccordionComponent label="Optimization and Content Promotion">
        <p>Do you need your content optimized for users and search engines? Avantcy Marketing's Boston content marketing
          service is here to create the best work for you. We also help with promotion to boost your brand
          awareness.</p>
      </AccordionComponent>
      <AccordionComponent label="Reporting">
        <p>After creating excellent content, our next goal is to track your ROI and give you reports to monitor your
          growth. Our reports also help you with enough data for improvement.</p>
      </AccordionComponent>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile"}>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img3} id={"choosing-pic"}/>
        </div>
        <div className={"flex-equal-width m-3 text-align-left"}>
          <div className={"text-align-center"}>
            <h2>GET MORE CUSTOMERS WITH CONTENT</h2>
          </div>
          <p className={"black-text align-items-center"}>The existence of numerous content forms makes reaching more
            customers easier. You can target various buyer ranges with different types of content that attract them.
            Some content types you can get from our content marketing services in Boston are:</p>
          <ul>
            <li><strong>Blog posts</strong></li>
            <li><strong>Infographics</strong></li>
            <li><strong>Videos</strong></li>
            <li><strong>Long-form content</strong></li>
            <li><strong>Online guides</strong></li>
            <li><strong>Slides</strong></li>
            <li><strong>Podcasts and audio content</strong></li>
            <li><strong>eBooks and more</strong></li>
          </ul>
          <p className={"black-text align-items-center"}>As a Boston content marketing team, we create any of the
            content mentioned above or others at your request. Afterward, we make a unique, competitive, actionable
            strategy that helps these quality content types reach your target audience. Just sit back and see how your
            site traffic increases while developing new improvement strategies.</p>
          <div className={"text-align-center"}>
            <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
          </div>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
          <div className={"flex-equal-width m-3 text-align-left"}>
            <h2 className={"text-align-center"}>WHY MARKET YOUR CONTENT WITH US</h2>
            <p>Your decision to choose Avantcy Marketing's content marketing services in Boston is the best decision you
              will make for your business. These are the reasons why such services are a superior choice:</p>
            <ul>
              <li><strong>All our strategies are tailored for your business</strong></li>
              <li><strong>We offer years of experience in content marketing</strong></li>
              <li><strong>We dedicate a unique team to manage your account to help you have a sense of
                sameness.</strong></li>
            </ul>
            <p>Avantcy Marketing offers you the best content marketing team with enthusiasm to deliver. If you take your
              content marketing seriously, join us today for top-notch work!</p>
          </div>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img4} id={"choosing-pic"}/>
          </div>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default contentMarketingPage;
